<template>
  <v-card
    outlined
    class="product-card product-card-selfscanning"
    :class="[
      promoClass,
      { 'last-item': item.itemId == lastItemId },
      { 'from-list': isForecastItem }
    ]"
    :id="`product-card-${item.product.slug}`"
    ref="productCardSelfscanning"
    :key="componentKey"
  >
    <div class="product" @click="$emit('selectProduct')">
      <div class="forecastItemLabel" v-if="isForecastItem">
        da prelevare <v-icon color="white">$expand</v-icon>
      </div>
      <img
        v-if="
          item.product.productInfos.DISABLE_BAG &&
            item.product.productInfos.DISABLE_BAG == 1 &&
            !isForecastItem
        "
        class="left-icon"
        src="img_layout/delivery.svg"
      />
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="item.product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :alt="'Immagine' + item.product.name"
            :title="
              item.product.name +
                ' (' +
                item.product.codInt +
                '-' +
                item.product.codVar +
                ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-space-between w-100 mt-3 ml-1 ml-sm-0"
          >
            <div>
              <div class="name font-weight-bold">
                {{ item.product.name }}
              </div>
              <div class="short_descr">
                {{ item.product.description }} {{ item.product.shortDescr }}
                <span
                  v-if="
                    item.product.productInfos &&
                      item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                      item.product.priceUmDisplay
                  "
                  >al {{ item.product.weightUnitDisplay }}
                  {{ $n(item.product.priceUmDisplay, "currency") }}</span
                >
              </div>
              <ProductPrice
                v-if="item.product.priceDisplay"
                :product="item.product"
              />
              <div
                v-else
                class="
      product-price d-flex justify-center"
              >
                <div class="cur-price">
                  {{ $n(item.unitPrice, "currency") }}
                </div>
              </div>
              <!-- <div
              v-else
              class="
      product-price d-flex justify-center"
            ></div> -->
              <span class="in-list-quantity py-2">
                {{ getDecodedListItemQuantity(item)
                }}{{ $n(item.product, "ssuffix") }}
              </span>
            </div>
            <div class="item-sector">
              <div
                v-if="
                  item.product.productInfos &&
                    item.product.productInfos.BLOCK_NAME
                "
                class="grey lighten-3 rounded"
              >
                <strong class="text-uppercase"
                  >{{ $t("selfScanning.cartItem.department") }}:
                </strong>
                {{ item.product.productInfos.BLOCK_NAME }}
              </div>
              <div
                v-if="
                  item.product.productInfos && item.product.productInfos.LANE
                "
                class="grey lighten-3 rounded"
              >
                <strong class="text-uppercase"
                  >{{ $t("selfScanning.cartItem.lane") }}:
                </strong>
                <span>{{ item.product.productInfos.LANE }}</span>
                <span
                  v-if="
                    item.product.productInfos && item.product.productInfos.RACK
                  "
                >
                  - {{ item.product.productInfos.RACK }}</span
                >
              </div>
            </div>
            <div
              class="product-price-total-container d-flex flex-row justify-space-between align-center flex-wrap mt-2"
            >
              <StepperSelfscanning :item="item" />
              <div class="product-list-price">
                {{ $n(item.grossTotal, "currency") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card-selfscanning {
  padding: 2px;
  .sep {
    display: none;
  }
  span + strong {
    .sep {
      display: initial;
    }
  }
  .name {
    font-size: 14px;
    color: $secondary;
  }
  .short_descr {
    font-size: 12px;
    color: var(--v-default-base);
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    min-height: 20px !important;
    -webkit-line-clamp: 1 !important;
  }
  .row-in-list {
    font-size: 12px;
  }
  .description {
    height: 205px !important;
  }
  .cur-price {
    font-size: 24px;
  }
  .old-price {
    font-size: 18px;
  }
  .product-list-price {
    font-size: 25px;
    font-weight: bold;
    color: $secondary;
  }
  .product-price {
    margin-top: 10px;
    width: fit-content;
  }
  .forecastItemLabel {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-40%, -50%) rotate(-90deg);
    text-align: center;
    white-space: nowrap;
    padding: 5px 10px;
    background-color: #f7a200;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-width: 100px;
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      top: 25%;
    }
  }
  .left-icon {
    top: 5px;
    left: 5px;
    position: absolute;
    width: 10%;
    background-color: #f7a200;
    padding: 5px;
    border-radius: 5px;
  }
}
.product-card-selfscanning.last-item {
  // border-color: $secondary;
  border: 3px solid $secondary;
  padding: 0px;
  .product {
    background-color: var(--v-secondary-lighten2);
  }
}
.product-card-selfscanning.from-list {
  border: 3px solid #f7a200;
  padding: 0px;
}
</style>
<script>
import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import LongPress from "vue-directive-long-press";
import SelfscanningService from "~/service/selfscanningService";
import ProductPrice from "@/components/product/ProductPrice.vue";
import StepperSelfscanning from "@/components/selfscanning/StepperSelfscanning.vue";
import { mask } from "vue-the-mask";

export default {
  name: "ProductCardSelfscanning",
  props: {
    item: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  components: { StepperSelfscanning, ProductPrice },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated",
      lastItemId: "custom/getLastItemSSC"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.item.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    // item() {
    //   this.key;
    //   let item = this.getItem({
    //     product: this.product,
    //     ...this.selectedOptions
    //   });
    //   return item;
    // },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.item.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.item.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.item.product.edge) {
        productClasses.push(this.item.product.badge.cssClass);
      }
      return productClasses;
    },
    isForecastItem() {
      return (
        this.item &&
        this.item.cartItemInfo &&
        (this.item.cartItemInfo.selQuantity ||
          this.item.cartItemInfo.selWeight) &&
        !(this.item.quantity > 0 || this.item.weight > 0)
      );
    }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    },
    getDecodedListItemQuantity(item) {
      var q = SelfscanningService.decodeListItemQuantity(item);
      if (q) {
        return this.$t("selfScanning.cartItem.expectedQuantity") + " " + q;
      }
    }
  },
  mounted() {
    if (this.item.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.item.product.selectOptions);
    }
  }
};
</script>
