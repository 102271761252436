import { render, staticRenderFns } from "./ProductCardSelfscanning.vue?vue&type=template&id=43f1cb86"
import script from "./ProductCardSelfscanning.vue?vue&type=script&lang=js"
export * from "./ProductCardSelfscanning.vue?vue&type=script&lang=js"
import style0 from "./ProductCardSelfscanning.vue?vue&type=style&index=0&id=43f1cb86&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports