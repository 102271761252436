var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:_vm.componentKey,ref:"productCardSelfscanning",staticClass:"product-card product-card-selfscanning",class:[
    _vm.promoClass,
    { 'last-item': _vm.item.itemId == _vm.lastItemId },
    { 'from-list': _vm.isForecastItem }
  ],attrs:{"outlined":"","id":`product-card-${_vm.item.product.slug}`}},[_c('div',{staticClass:"product",on:{"click":function($event){return _vm.$emit('selectProduct')}}},[(_vm.isForecastItem)?_c('div',{staticClass:"forecastItemLabel"},[_vm._v(" da prelevare "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("$expand")])],1):_vm._e(),(
        _vm.item.product.productInfos.DISABLE_BAG &&
          _vm.item.product.productInfos.DISABLE_BAG == 1 &&
          !_vm.isForecastItem
      )?_c('img',{staticClass:"left-icon",attrs:{"src":"img_layout/delivery.svg"}}):_vm._e(),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column body"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"src":_vm.item.product.mediaURL,"onerror":"this.onerror=null;this.src='/no-icon.png'","alt":'Immagine' + _vm.item.product.name,"title":_vm.item.product.name +
              ' (' +
              _vm.item.product.codInt +
              '-' +
              _vm.item.product.codVar +
              ')'}}),_c('div',{staticClass:"description default--text d-flex flex-column justify-space-between w-100 mt-3 ml-1 ml-sm-0"},[_c('div',[_c('div',{staticClass:"name font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('div',{staticClass:"short_descr"},[_vm._v(" "+_vm._s(_vm.item.product.description)+" "+_vm._s(_vm.item.product.shortDescr)+" "),(
                  _vm.item.product.productInfos &&
                    _vm.item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    _vm.item.product.priceUmDisplay
                )?_c('span',[_vm._v("al "+_vm._s(_vm.item.product.weightUnitDisplay)+" "+_vm._s(_vm.$n(_vm.item.product.priceUmDisplay, "currency")))]):_vm._e()]),(_vm.item.product.priceDisplay)?_c('ProductPrice',{attrs:{"product":_vm.item.product}}):_c('div',{staticClass:"product-price d-flex justify-center"},[_c('div',{staticClass:"cur-price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.unitPrice, "currency"))+" ")])]),_c('span',{staticClass:"in-list-quantity py-2"},[_vm._v(" "+_vm._s(_vm.getDecodedListItemQuantity(_vm.item))+_vm._s(_vm.$n(_vm.item.product, "ssuffix"))+" ")])],1),_c('div',{staticClass:"item-sector"},[(
                _vm.item.product.productInfos &&
                  _vm.item.product.productInfos.BLOCK_NAME
              )?_c('div',{staticClass:"grey lighten-3 rounded"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("selfScanning.cartItem.department"))+": ")]),_vm._v(" "+_vm._s(_vm.item.product.productInfos.BLOCK_NAME)+" ")]):_vm._e(),(
                _vm.item.product.productInfos && _vm.item.product.productInfos.LANE
              )?_c('div',{staticClass:"grey lighten-3 rounded"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("selfScanning.cartItem.lane"))+": ")]),_c('span',[_vm._v(_vm._s(_vm.item.product.productInfos.LANE))]),(
                  _vm.item.product.productInfos && _vm.item.product.productInfos.RACK
                )?_c('span',[_vm._v(" - "+_vm._s(_vm.item.product.productInfos.RACK))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"product-price-total-container d-flex flex-row justify-space-between align-center flex-wrap mt-2"},[_c('StepperSelfscanning',{attrs:{"item":_vm.item}}),_c('div',{staticClass:"product-list-price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }