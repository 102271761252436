<template>
  <div class="stepper stepper-selfscanning quantity-box mr-2">
    <div class="stp" :class="{ 'manual-input': inset.showInput }">
      <div class="quantity-container">
        <div
          class="qty quantity-value d-flex flex-row"
          v-if="inset.showInput === false"
          :class="{ 'can-edit': canChangeMode() }"
        >
          <div
            class="edit"
            @click.stop.prevent="changeMode()"
            v-if="quantity != 0"
          >
            <span> {{ quantity }} {{ unit }}</span>
            <v-icon
              class="ml-2"
              color="primary"
              @click.prevent.stop="changeMode(true)"
              >$edit</v-icon
            >
          </div>
          <div class="qty-empty" v-if="quantity == 0">
            <span>--</span>
          </div>
        </div>
        <div class="qty d-flex flex-row" v-if="inset.showInput === true">
          <button
            @click.prevent.stop="changeMode(true)"
            class="button  button-clear button-energized"
          >
            <v-icon>$close</v-icon>
          </button>
          <input
            min="0"
            type="number"
            :id="'val-input-' + item.product.productId"
            v-model="inset.quantity"
          />
          <button
            @click.prevent.stop="confirmEdit('selfscanning')"
            class="button button-clear button-energized"
          >
            <v-icon>$success</v-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.edit {
  span {
    display: flex;
    align-items: center;
  }
  .v-icon.v-icon {
    font-size: 20px;
  }
}
.stp {
  display: flex;
}
.quantity-container {
  display: flex;
  width: fit-content;
  .qty.quantity-value {
    display: flex;
    justify-content: center;
    .edit {
      display: flex;
    }
  }
  .qty {
    input {
      max-width: 80px;
      text-align: center;
    }
    width: 100%;
    display: inline-block;
    height: 28px;
    color: #002f65;
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    overflow: hidden;
  }
}
</style>
<script>
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import AnalyticsService from "~/service/analyticsService";
import SelfscanningService from "~/service/selfscanningService";
import ListService from "~/service/listService";
import LongPress from "vue-directive-long-press";
import { mapActions } from "vuex";

export default {
  name: "StepperSelfscanning",
  data() {
    return {
      inset: {
        showInput: false,
        quantity: 0,
        quantityPerUnit: 0
      }
    };
  },
  directives: {
    "long-press": LongPress
  },
  props: {
    item: { Type: Object, required: true }
  },
  computed: {
    hasCustomWeight(prod) {
      if (prod) {
        for (var j = 0; j < prod.selectOptions.length; j++) {
          var option = prod.selectOptions[j];
          if (option.parameterName == "CUSTOM_WEIGHT") {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      updateCartSSC: "custom/updateCartSSC"
    }),
    confirmEdit(type) {
      if (this.inset.quantity != null && this.inset.quantity != undefined) {
        this.inset.showInput = false;
        if (!type) {
          CartService.setItem(this.item, this.inset.quantity).then(function() {
            AnalyticsService.setProduct(this.item, this.inset.quantity);
          });
        } else if (type == "list") {
          ListService.setItem(
            this.item,
            this.list.listId,
            this.inset.quantity
          ).then(function(list) {
            for (var i = 0; i < list.wishlistItems.length; i++) {
              if (list.wishlistItems[i].itemId == this.item.itemId) {
                this.item.weight = list.wishlistItems[i].weight;
                this.item.quantity = list.wishlistItems[i].quantity;
                break;
              }
            }
          });
        } else if (type == "selfscanning") {
          SelfscanningService.setItem(
            this.item,
            parseInt(this.inset.quantity)
          ).then(data => {
            console.log(data.data.data);
            this.updateCartSSC(data.data.data);
          });
        }
      }
    },
    init() {
      if (this.item.product.leadTime) {
        this.item.product.leadDate = this.$dayjs(
          this.item.product.leadTime,
          "days"
        );
      }
    },
    canChangeMode() {
      if (
        this.manualStepperEnabled &&
        (this.item.product.productInfos.TIPOLOGIA == "Pezzo" ||
          this.item.product.productInfos.TIPOLOGIA == "Confezione") &&
        this.item.product.productInfos.QTY_LOCKED != "true"
      ) {
        return true;
      }
      return false;
    },
    changeMode(alwaysEnabled) {
      if (alwaysEnabled || this.canChangeMode()) {
        if (this.inset.showInput) {
          this.inset.showInput = false;
          this.init();
        } else {
          this.inset.showInput = true;
          this.init();
          // setTimeout(function() {
          //   var el = document.getElementById(
          //     "val-input-" + this.product.productId
          //   );
          //   el[0].select();
          // });
        }
        this.inset.quantity = this.quantity;
      }
    }
  },
  mounted() {
    if (this.item.product.leadTime) {
      this.item.product.leadDate = this.$dayjs(
        this.item.product.leadTime,
        "days"
      );
    }
    this.inset.quantity = this.quantity;
  },
  created() {
    global.EventBus.$on("initQuantityPerUnit", () => {
      this.init();
    });
  }
  // watch: {
  //   item(newValue, oldValue) {
  //     if (newValue && oldValue && newValue.itemId != oldValue.itemId) {
  //       this.init();
  //     }
  //   }
  // }
};
</script>
