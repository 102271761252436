<template>
  <div>
    <div v-if="cartSSC && cartSSC.cartItems && cartSSC.cartItems.length > 0">
      <div class="product-grid product-grid-selfscanning mt-2 mt-sm-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            v-for="(cartItem, index) in cartSSC.cartItems"
            :key="cartItem.product.productId"
            class="product-col"
          >
            <ProductCardSelfscanning :item="cartItem" :position="index" />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else-if="cartSSC && cartSSC.cartItems" class="text-center">
      <h1>{{ $t("selfScanning.homepage.scanFirstProduct") }}</h1>
    </div>
    <div v-else class="text-center">
      <h1>
        {{ $t("selfScanning.homepage.scanInitCode") }}
      </h1>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductCardSelfscanning from "@/components/selfscanning/ProductCardSelfscanning.vue";
export default {
  name: "SSCHome",
  components: {
    ProductCardSelfscanning
  },
  computed: {
    ...mapGetters({
      cartSSC: "custom/getCartSSC"
    })
  }
};
</script>
